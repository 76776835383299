<template>
  <b-card
    v-if="data"
    class="card-congratulation-medal"
  >
    <h5 class="mb-2">
      Congratulations 🎉 {{ data.userData.us_nam }}!
    </h5>

    <div v-if="data.userData.us_typ == 'A' || data.userData.us_typ == 'T'">
      <i class="font-small-3">
        You are <b class="text-primary">{{ resolveAccountType(data.userData.acc_typ) }}'s</b> Top Sales Agent!
      </i>
      🏆
      <b-card-text class="font-small-3">
        <h1 class="mb-0">
          <b-link>{{ kFormatter(data.customers) }}</b-link>
        </h1>
        Customers Onboarded
      </b-card-text>
    </div>

    <div v-else>
      <b-card-text class="font-small-3">
        <b class="text-primary">{{ resolveAccountType(data.userData.acc_typ) }}</b> has Onboarded
        <h1 class="mb-0">
          <b-link>{{ kFormatter(data.customers) }}</b-link>
        </h1>
        Customers
      </b-card-text>
    </div>

    <!--  -->
    <!-- <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    >
      View Sales
    </b-button> -->
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { resolveAccountType } from '@/utils/common-functions/resolvers'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
    resolveAccountType,
  },
}
</script>
