<template>
  <div>
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 206, 38, 0.15)'"
          variant="outline-primary"
          class="mb-2 float-right"
          size="sm"
          @click="exportExcel"
        >
          Export
        </b-button>

        <!-- toggle button -->
        <b-button
          v-ripple.400="'rgba(255, 206, 38, 0.15)'"
          v-b-toggle.date-filter
          variant="outline-primary"
          class="mb-2 mx-2 float-right"
          size="sm"
        >
          Filter
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>

        <b-collapse
          id="date-filter"
        >
          <subscriptions-overview-list-filters
            :start-date-filter.sync="startDateFilter"
            :end-date-filter.sync="endDateFilter"
          />
        </b-collapse>
      </b-col>
    </b-row>

    <section id="dashboard-sales">
      <b-row class="match-height">
        <b-col
          xl="4"
          md="6"
        >
          <sales-medal
            v-if="subscriptionsOverview && subscriptionsOverview.hasOwnProperty('customers')"
            :data="subscriptionsOverview"
          />

          <card-skeleton
            v-else
            :animation="'wave'"
          />
        </b-col>

        <b-col
          xl="4"
          md="6"
        >
          <!-- <sales-statistics :data="subscriptionsOverview.revenue" /> -->

          <statistic-card-with-line-chart
            v-if="subscriptionsOverview && subscriptionsOverview.hasOwnProperty('customers')"
            icon="UsersIcon"
            :statistic="kFormatter(subscriptionsOverview.customers)"
            statistic-title="Customers Onboarded"
            :color="'success'"
            :chart-data="[{ name: 'Onboard Growth', data: subscriptionsOverview.growthOnboardings }]"
          />

          <card-skeleton
            v-else
            :animation="'wave'"
          />
        </b-col>

        <b-col
          xl="4"
          md="6"
        >
          <statistic-card-with-line-chart
            v-if="subscriptionsOverview && subscriptionsOverview.hasOwnProperty('revenue')"
            icon="DollarSignIcon"
            :statistic="`${kFormatter(subscriptionsOverview.revenue)}`"
            statistic-title="Revenue"
            :chart-data="[{ name: 'Revenvue Growth', data: subscriptionsOverview.growthRevenue }]"
          />

          <card-skeleton
            v-else
            :animation="'wave'"
          />

          <!-- <sales-profit-chart :data="{ series: [{ name: 'Onboard Growth', data: subscriptionsOverview.growthOnboardings }] }" /> -->
        </b-col>

        <!-- <b-col
          xl="2"
          md="6"
        >
          <sales-profit-chart :data="{ series: [{ name: 'Revenue Growth', data: subscriptionsOverview.growthRevenue }] }" />
        </b-col> -->
      </b-row>

      <!-- <b-row class="match-height">
        <b-col lg="4">
          <b-row class="match-height">
            <b-col
              lg="6"
              md="3"
              cols="6"
            >
              <sales-order-chart :data="data.statisticsOrder" />
            </b-col>

            <b-col
              lg="6"
              md="3"
              cols="6"
            >
              <sales-profit-chart :data="data.statisticsProfit" />
            </b-col>

            <b-col
              lg="12"
              md="6"
            >
              <sales-earnings-chart :data="data.earningsChart" />
            </b-col>
          </b-row>
        </b-col>

        <b-col lg="8">
          <sales-revenue-report :data="data.revenue" />
        </b-col>
      </b-row> -->

      <b-row class="match-height">
        <!-- Subscription User Type -->
        <b-col
          lg="4"
          md="6"
        >
          <sales-subscriptions-report
            v-if="subscriptionsUserTypes"
            :title="'User Types'"
            :data="subscriptionsUserTypes"
            :start-date="startDateFilter"
            :end-date="endDateFilter"
          />

          <card-skeleton
            v-else
            :animation="'wave'"
          />
          <!-- <sales-goal-overview :data="data.goalOverview" /> -->
        </b-col>

        <!-- Subscription Packages -->
        <b-col
          lg="4"
          md="6"
        >
          <sales-subscriptions-report
            v-if="subscriptionsPackages"
            :title="'Packages'"
            :data="subscriptionsPackages"
            :start-date="startDateFilter"
            :end-date="endDateFilter"
          />

          <card-skeleton
            v-else
            :animation="'wave'"
          />
          <!-- <sales-transactions :data="data.transactionData" /> -->
        </b-col>

        <b-col
          lg="4"
          md="6"
        >
          <sales-subscriptions-report
            v-if="subscriptionsCities"
            :title="'Cities'"
            :data="subscriptionsCities"
            :start-date="startDateFilter"
            :end-date="endDateFilter"
          />

          <card-skeleton
            v-else
            :animation="'wave'"
          />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import SalesMedal from './SalesMedal.vue'
import SalesStatistics from './SalesStatistics.vue'
import SalesRevenueReport from './SalesRevenueReport.vue'
import SalesOrderChart from './SalesOrderChart.vue'
import SalesProfitChart from './SalesProfitChart.vue'
import SalesEarningsChart from './SalesEarningsChart.vue'
import SalesCompanyTable from './SalesCompanyTable.vue'
import SalesMeetup from './SalesMeetup.vue'
import SalesBrowserStates from './SalesBrowserStates.vue'
import SalesGoalOverview from './SalesGoalOverview.vue'
import SalesTransactions from './SalesTransactions.vue'
import SubscriptionsOverviewListFilters from '@/views/sales/subscriptions/reports/subscriptions-overview/SubscriptionsOverviewListFilters.vue'
import useSubscriptionsOverviewList from '@/views/sales/subscriptions/reports/subscriptions-overview/useSubscriptionsOverviewList'
import subscriptionStoreModule from '@/views/sales/subscriptions/subscriptionStoreModule'
import SalesSubscriptionsReport from './SalesSubscriptionsReport.vue'
import StatisticCardWithLineChart from '@/@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import CardSkeleton from '@/components/skeleton/card-skeleton/CardSkeleton.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCollapse,

    SalesMedal,
    SalesStatistics,
    SalesRevenueReport,
    SalesOrderChart,
    SalesProfitChart,
    SalesEarningsChart,
    SalesCompanyTable,
    SalesMeetup,
    SalesBrowserStates,
    SalesGoalOverview,
    SalesTransactions,
    SubscriptionsOverviewListFilters,
    SalesSubscriptionsReport,
    StatisticCardWithLineChart,
    CardSkeleton,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  methods: {
    kFormatter,
  },
  setup() {
    const SUBSCRIPTION_OVERVIEW_STORE_MODULE_NAME = 'app-subscription'

    // Register module
    if (!store.hasModule(SUBSCRIPTION_OVERVIEW_STORE_MODULE_NAME)) store.registerModule(SUBSCRIPTION_OVERVIEW_STORE_MODULE_NAME, subscriptionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUBSCRIPTION_OVERVIEW_STORE_MODULE_NAME)) store.unregisterModule(SUBSCRIPTION_OVERVIEW_STORE_MODULE_NAME)
    })

    const {
      subscriptionsOverview,

      subscriptionsPackages,
      totalSubscriptionsPackages,

      subscriptionsCities,
      totalSubscriptionsCities,

      subscriptionsUserTypes,
      totalSubscriptionsUserTypes,

      fetchData,
      exportExcel,

      // Filters
      startDateFilter,
      endDateFilter,
    } = useSubscriptionsOverviewList()

    // Call Functions
    fetchData()

    return {
      subscriptionsOverview,

      subscriptionsPackages,
      totalSubscriptionsPackages,

      subscriptionsCities,
      totalSubscriptionsCities,

      subscriptionsUserTypes,
      totalSubscriptionsUserTypes,

      exportExcel,

      // Filters
      startDateFilter,
      endDateFilter,
    }
  },
  // created() {
  //   // data
  //   this.$http.get('/ecommerce/data')
  //     .then(response => {
  //       this.data = response.data

  //       // ? Your API will return name of logged in user or you might just directly get name of logged in user
  //       // ? This is just for demo purpose
  //       const userData = computed(() => store.getters['app-authentication/getUserDetails']).value
  //       this.data.congratulations.name = userData.us_nam
  //     })
  // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-sales.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
