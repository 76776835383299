import { ref, watch, computed } from '@vue/composition-api'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import XLSX from 'xlsx'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'
import {
  resolveUserType,
  resolveAccountType,
  resolveTenure,
  resolvePaymentMethod,
} from '@/utils/common-functions/resolvers'

export default function useSubscriptionsOverviewList() {
  // Use toast
  const toast = useToast()

  // Subscriptions Overview
  const totalSubscriptions = ref(null)
  const subscriptionsOverview = ref(null)
  const oddSubscriptionsOverview = ref(null)
  const evenSubscriptionsOverview = ref(null)

  // Subscriptions Store Types
  const subscriptionsStoreTypes = ref(null)
  const oddSubscriptionsStoreTypes = ref(null)
  const evenSubscriptionsStoreTypes = ref(null)
  const totalSubscriptionsStoreTypes = ref(null)

  // Subscriptions Deparments
  const subscriptionsDepartments = ref(null)
  const oddSubscriptionsDepartments = ref(null)
  const evenSubscriptionsDepartments = ref(null)
  const totalSubscriptionsDepartments = ref(null)

  // Subscriptions Agent Types
  const subscriptionsAgentTypes = ref(null)
  const oddSubscriptionsAgentTypes = ref(null)
  const evenSubscriptionsAgentTypes = ref(null)
  const totalSubscriptionsAgentTypes = ref(null)

  // Subscriptions Packages
  const subscriptionsPackages = ref(null)
  const oddSubscriptionsPackages = ref(null)
  const evenSubscriptionsPackages = ref(null)
  const totalSubscriptionsPackages = ref(null)

  // Subscriptions User Types
  const subscriptionsUserTypes = ref(null)
  const oddSubscriptionsUserTypes = ref(null)
  const evenSubscriptionsUserTypes = ref(null)
  const totalSubscriptionsUserTypes = ref(null)

  // Subscriptions Cities
  const subscriptionsCities = ref(null)
  const oddSubscriptionsCities = ref(null)
  const evenSubscriptionsCities = ref(null)
  const totalSubscriptionsCities = ref(null)

  const exportSubscriptionsOverview = ref(null)
  const exportSubscriptionsStoreTypes = ref(null)
  const exportSubscriptionsDepartments = ref(null)
  const exportSubscriptionsAgentTypes = ref(null)
  const exportSubscriptionsPackages = ref(null)
  const exportSubscriptionsUserTypes = ref(null)
  const exportSubscriptionsCities = ref(null)
  const exportSubscriptionsExcel = ref(null)

  // Filters
  const todaysDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
  const startDateFilter = ref(todaysDate)
  const endDateFilter = ref(null)

  const userDetails = computed(() => store.getters['app-authentication/getUserDetails']).value

  async function fetchSubscriptionsOverview() {
    try {
      subscriptionsOverview.value = null
      const response = await store.dispatch('app-subscription/fetchSubscriptionsOververview', {
        start_date: startDateFilter.value,
        end_date: endDateFilter.value,
      })
      subscriptionsOverview.value = response.data.data[0]
      subscriptionsOverview.value.userData = userDetails

      // subscriptionsOverview.value.revenue = [{
      //   title: 'Revenue',
      //   subtitle: response.data.data[0].revenue,
      //   icon: 'DollarSignIcon',
      //   color: 'light-primary',
      // }]
      // oddSubscriptionsOverview.value = subscriptionsOverview.value.filter((obj, index) => !(index % 2 === 0))
      // evenSubscriptionsOverview.value = subscriptionsOverview.value.filter((obj, index) => index % 2 === 0)
      // totalSubscriptions.value = response.data.data[0].total_subscriptions
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Subscriptions Overview',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  async function fetchSubscriptionsStoreTypes() {
    try {
      const response = await store.dispatch('app-subscription/fetchSubscriptionsStoreTypes', {
        start_date: startDateFilter.value,
        end_date: endDateFilter.value,
      })
      subscriptionsStoreTypes.value = response.data.data[0].subscriptions_storetypes
      oddSubscriptionsStoreTypes.value = subscriptionsStoreTypes.value.filter((obj, index) => !(index % 2 === 0))
      evenSubscriptionsStoreTypes.value = subscriptionsStoreTypes.value.filter((obj, index) => index % 2 === 0)
      totalSubscriptionsStoreTypes.value = subscriptionsStoreTypes.value.reduce((acc, obj) => acc + obj.subscriptions, 0)
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Subscriptions Store Types',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  async function fetchSubscriptionsDepartments() {
    try {
      const response = await store.dispatch('app-subscription/fetchSubscriptionsDepartments', {
        start_date: startDateFilter.value,
        end_date: endDateFilter.value,
      })
      subscriptionsDepartments.value = response.data.data[0].subscriptions_departments
      oddSubscriptionsDepartments.value = subscriptionsDepartments.value.filter((obj, index) => !(index % 2 === 0))
      evenSubscriptionsDepartments.value = subscriptionsDepartments.value.filter((obj, index) => index % 2 === 0)
      totalSubscriptionsDepartments.value = subscriptionsDepartments.value.reduce((acc, obj) => acc + obj.subscriptions, 0)
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Subscriptions Departments',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  async function fetchSubscriptionsAgentTypes() {
    try {
      const response = await store.dispatch('app-subscription/fetchSubscriptionsAgentTypes', {
        start_date: startDateFilter.value,
        end_date: endDateFilter.value,
      })
      subscriptionsAgentTypes.value = response.data.data[0].subscription_agents
      oddSubscriptionsAgentTypes.value = subscriptionsAgentTypes.value.filter((obj, index) => !(index % 2 === 0))
      evenSubscriptionsAgentTypes.value = subscriptionsAgentTypes.value.filter((obj, index) => index % 2 === 0)
      totalSubscriptionsAgentTypes.value = subscriptionsAgentTypes.value.reduce((acc, obj) => acc + obj.subscriptions, 0)
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Subscriptions Agent Types',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  async function fetchSubscriptionsUserTypes() {
    try {
      subscriptionsUserTypes.value = null
      const response = await store.dispatch('app-subscription/fetchSubscriptionsUserTypes', {
        start_date: startDateFilter.value,
        end_date: endDateFilter.value,
      })
      subscriptionsUserTypes.value = response.data.data
      oddSubscriptionsUserTypes.value = subscriptionsUserTypes.value.filter((obj, index) => !(index % 2 === 0))
      evenSubscriptionsUserTypes.value = subscriptionsUserTypes.value.filter((obj, index) => index % 2 === 0)
      totalSubscriptionsUserTypes.value = subscriptionsUserTypes.value.reduce((acc, obj) => acc + obj.subscriptions, 0)
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Subscriptions Packages',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  async function fetchSubscriptionsPackages() {
    try {
      subscriptionsPackages.value = null
      const response = await store.dispatch('app-subscription/fetchSubscriptionsPackages', {
        start_date: startDateFilter.value,
        end_date: endDateFilter.value,
      })
      subscriptionsPackages.value = response.data.data
      oddSubscriptionsPackages.value = subscriptionsPackages.value.filter((obj, index) => !(index % 2 === 0))
      evenSubscriptionsPackages.value = subscriptionsPackages.value.filter((obj, index) => index % 2 === 0)
      totalSubscriptionsPackages.value = subscriptionsPackages.value.reduce((acc, obj) => acc + obj.subscriptions, 0)
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Subscriptions Packages',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  async function fetchSubscriptionsCities() {
    try {
      subscriptionsCities.value = null
      const response = await store.dispatch('app-subscription/fetchSubscriptionsCities', {
        start_date: startDateFilter.value,
        end_date: endDateFilter.value,
      })
      subscriptionsCities.value = response.data.data
      oddSubscriptionsCities.value = subscriptionsCities.value.filter((obj, index) => !(index % 2 === 0))
      evenSubscriptionsCities.value = subscriptionsCities.value.filter((obj, index) => index % 2 === 0)
      totalSubscriptionsCities.value = subscriptionsCities.value.reduce((acc, obj) => acc + obj.subscriptions, 0)
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Subscriptions Agent Types',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const fetchData = () => {
    fetchSubscriptionsOverview()
    // fetchSubscriptionsStoreTypes()
    // fetchSubscriptionsDepartments()
    // fetchSubscriptionsAgentTypes()
    fetchSubscriptionsPackages()
    fetchSubscriptionsUserTypes()
    fetchSubscriptionsCities()
  }

  watch([startDateFilter, endDateFilter], () => {
    fetchData()
  })

  const formatData = () => {
    exportSubscriptionsOverview.value = subscriptionsOverview.value.map(obj => Object.values(obj))
    exportSubscriptionsStoreTypes.value = subscriptionsStoreTypes.value.map(obj => Object.values(obj))
    exportSubscriptionsDepartments.value = subscriptionsDepartments.value.map(obj => Object.values(obj))
    exportSubscriptionsAgentTypes.value = subscriptionsAgentTypes.value.map(obj => Object.values(obj))
    exportSubscriptionsPackages.value = subscriptionsPackages.value.map(obj => Object.values(obj))
    exportSubscriptionsUserTypes.value = subscriptionsUserTypes.value.map(obj => Object.values(obj))
    exportSubscriptionsCities.value = subscriptionsCities.value.map(obj => Object.values(obj))
  }

  const exportPDF = () => {
    formatData()

    const exportObjs = [
      {
        title: 'Subscriptions Overview',
        rawArray: subscriptionsOverview.value,
        exportArray: exportSubscriptionsOverview.value,
        totals: totalSubscriptions.value,
      },
      {
        title: 'Subscriptions Store Types',
        rawArray: subscriptionsStoreTypes.value,
        exportArray: exportSubscriptionsStoreTypes.value,
        totals: totalSubscriptionsStoreTypes.value,
      },
      {
        title: 'Subscriptions Departments',
        rawArray: subscriptionsDepartments.value,
        exportArray: exportSubscriptionsDepartments.value,
        totals: totalSubscriptionsDepartments.value,
      },
      {
        title: 'Subscriptions Agent Types',
        rawArray: subscriptionsAgentTypes.value,
        exportArray: exportSubscriptionsAgentTypes.value,
        totals: totalSubscriptionsAgentTypes.value,
      },
      {
        title: 'Subscriptions User Types',
        rawArray: subscriptionsUserTypes.value,
        exportArray: exportSubscriptionsUserTypes.value,
        totals: totalSubscriptionsUserTypes.value,
      },
      {
        title: 'Subscriptions Packages',
        rawArray: subscriptionsPackages.value,
        exportArray: exportSubscriptionsPackages.value,
        totals: totalSubscriptionsPackages.value,
      },
      {
        title: 'Subscriptions Cities',
        rawArray: subscriptionsCities.value,
        exportArray: exportSubscriptionsCities.value,
        totals: totalSubscriptionsCities.value,
      },
    ]

    const doc = new jsPDF('p', 'px', 'a4')

    doc.setFontSize(10)
    doc.setFont('helvetica', 'bolditalic')
    doc.text('Subscriptions Snapshot', 220, 20, null, null, 'center')
    doc.text(`From ${startDateFilter.value ? startDateFilter.value : '-'} till ${endDateFilter.value ? endDateFilter.value : '-'}`, 220, 30, null, null, 'center')

    doc.autoTable({
      head: [[{
        content: 'Total Subscriptions',
        colSpan: 1,
        styles: { halign: 'center', fillColor: [82, 67, 170] },
      }]],
      body: [[totalSubscriptions.value]],
      theme: 'striped',
      margin: { top: 40 },
      bodyStyles: {
        fillColor: [241, 196, 15],
        fontSize: 40,
        halign: 'center',
      },
    })

    for (let index = 0; index < exportObjs.length; index++) {
      if (exportObjs[index].exportArray && exportObjs[index].exportArray.length) {
        doc.autoTable({
          head: [[{
            content: exportObjs[index].title,
            colSpan: 2,
            styles: { halign: 'center', fillColor: [82, 67, 170] },
          }]],
          body: exportObjs[index].exportArray,
          foot: [['Totals', exportObjs[index].totals]],
          theme: 'striped',
          footStyles: {
            fillColor: [200, 244, 217],
            textColor: [47, 131, 84],
          },
        })
      }
    }

    doc.save(`Subscriptions Summary - From ${startDateFilter.value ? startDateFilter.value : '-'} till ${endDateFilter.value ? endDateFilter.value : '-'}.pdf`)
  }

  const {
    callAccountType,
    callUserType,
    callTenure,
    callPaymentMethods,
  } = useSystemCodes()
  callAccountType()
  callUserType()
  callTenure()
  callPaymentMethods()

  const downloadExcel = () => {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(exportSubscriptionsExcel.value)
    XLSX.utils.book_append_sheet(wb, ws, 'subscriptions')
    XLSX.writeFile(wb, 'subscriptions.xlsx')
  }

  const formatExportSubscriptionsData = data => {
    exportSubscriptionsExcel.value = data.map(obj => {
      const tempObj = { ...obj }
      tempObj.UserType = resolveUserType(obj.UserType)
      tempObj.AgentName = !obj.AgentName ? 'Self Onboarded Customer' : obj.AgentName
      tempObj.AgentType = obj.AgentType ? resolveAccountType(obj.AgentType) : 'Self Onboarded Customer'
      tempObj.Tenure = resolveTenure(obj.Tenure)
      tempObj.PaymentMethod = resolvePaymentMethod(obj.PaymentMethod)

      return tempObj
    })
    downloadExcel()
  }

  const exportExcel = async () => {
    try {
      const response = await store.dispatch('app-subscription/fetchSubscriptionsExport', {
        start_date: startDateFilter.value,
        end_date: endDateFilter.value,
      })

      if (!response.data.data || response.data.data.length === 0) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Data Not Found',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      formatExportSubscriptionsData(response.data.data)
      // downloadExcel()
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Subscriptions Export',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    totalSubscriptions,
    subscriptionsOverview,
    oddSubscriptionsOverview,
    evenSubscriptionsOverview,

    subscriptionsStoreTypes,
    oddSubscriptionsStoreTypes,
    evenSubscriptionsStoreTypes,
    totalSubscriptionsStoreTypes,

    subscriptionsDepartments,
    oddSubscriptionsDepartments,
    evenSubscriptionsDepartments,
    totalSubscriptionsDepartments,

    subscriptionsAgentTypes,
    oddSubscriptionsAgentTypes,
    evenSubscriptionsAgentTypes,
    totalSubscriptionsAgentTypes,

    subscriptionsUserTypes,
    oddSubscriptionsUserTypes,
    evenSubscriptionsUserTypes,
    totalSubscriptionsUserTypes,

    subscriptionsPackages,
    oddSubscriptionsPackages,
    evenSubscriptionsPackages,
    totalSubscriptionsPackages,

    subscriptionsCities,
    oddSubscriptionsCities,
    evenSubscriptionsCities,
    totalSubscriptionsCities,

    fetchData,
    exportPDF,
    exportExcel,

    // Filters
    startDateFilter,
    endDateFilter,
  }
}
