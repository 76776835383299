<template>
  <b-card>
    <b-skeleton
      :animation="animation"
      width="85%"
    />
    <b-skeleton
      :animation="animation"
      width="55%"
    />
    <b-skeleton
      :animation="animation"
      width="70%"
    />
  </b-card>
</template>

<script>
import { BCard, BSkeleton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BSkeleton,
  },
  props: {
    animation: {
      type: String,
      default: 'wave',
    },
  },
}
</script>
