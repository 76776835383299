<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label for="start-date">Start date</label>
          <b-form-datepicker
            id="start-date"
            :value="startDateFilter"
            class="mb-1"
            today-button
            reset-button
            close-button
            @input="(val) => $emit('update:startDateFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label for="end-date">End date</label>
          <b-form-datepicker
            id="end-date"
            :value="endDateFilter"
            class="mb-1"
            today-button
            reset-button
            close-button
            @input="(val) => $emit('update:endDateFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormDatepicker,
  },
  props: {
    startDateFilter: {
      type: [String, null],
      default: null,
    },
    endDateFilter: {
      type: [String, null],
      default: null,
    },
  },
}
</script>
