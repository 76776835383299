<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>
        <h4>{{ title }}</h4>
        <!-- <br> -->
        <div
          v-if="startDate || endDate"
          class="d-flex align-items-center"
        >
          <feather-icon
            size="14"
            icon="CalendarIcon"
            class="text-muted"
          />
          <small
            v-if="startDate"
            class="text-muted"
          >
            &nbsp;  {{ formatDate(startDate, { month: 'short', day: 'numeric', year: 'numeric' }) }}
          </small>
          <small
            v-if="endDate"
            class="text-muted"
          >
            &nbsp; - &nbsp; {{ formatDate(endDate, { month: 'short', day: 'numeric', year: 'numeric' }) }}
          </small>
        </div>
      </b-card-title>

      <!-- <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Year
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <div v-if="data && data.length > 0">
        <div
          v-for="(obj, key) in data.slice(0, 4)"
          :key="key"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside v-if="obj.avatar">
              <b-avatar
                rounded
                size="42"
                :variant="obj.avatarVariant"
              >
                <feather-icon
                  size="18"
                  :icon="obj.avatar"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ Object.values(obj)[0] }}
              </h6>
              <small>{{ Object.values(obj).length === 3 ? Object.values(obj)[1] : '' }}</small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-success">
            {{ Object.values(obj).length !== 3 ? kFormatter(Object.values(obj)[1]) : kFormatter(Object.values(obj)[2]) }}
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-muted">
          No Records..
        </p>
      </div>

    </b-card-body>

    <b-card-footer :footer-class="['border-0', 'text-center']">
      <b-row v-if="data && data.length > 0">
        <b-col>
          <b-link
            :to="{ name: 'sales-reports-subscriptions-overview' }"
            class="text-center"
          >
            More
            <feather-icon
              size="12"
              icon="ChevronsDownIcon"
            />
          </b-link>
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BCardFooter, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BLink,
} from 'bootstrap-vue'
import { kFormatter, formatDate } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardFooter,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BLink,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  methods: {
    kFormatter,
    formatDate,
  },
}
</script>
