import {
  subscriptionsAgentTypesAPI,
  subscriptionsCitiesAPI,
  subscriptionsDepartmentsAPI,
  subscriptionsExportAPI,
  subscriptionsInvoiceAPI,
  subscriptionsOverviewAPI,
  subscriptionsPackagesAPI,
  subscriptionsStoreTypesAPI,
  subscriptionsUserTypesAPI,
} from '@/services/apis'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchSubscriptionsOververview(ctx, queryParams) {
      try {
        return await this.$axios.get(subscriptionsOverviewAPI, { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async fetchSubscriptionsStoreTypes(ctx, queryParams) {
      try {
        return await this.$axios.get(subscriptionsStoreTypesAPI, { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async fetchSubscriptionsDepartments(ctx, queryParams) {
      try {
        return await this.$axios.get(subscriptionsDepartmentsAPI, { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async fetchSubscriptionsAgentTypes(ctx, queryParams) {
      try {
        return await this.$axios.get(subscriptionsAgentTypesAPI, { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async fetchSubscriptionsCities(ctx, queryParams) {
      try {
        return await this.$axios.get(subscriptionsCitiesAPI, { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async fetchSubscriptionsInvoice(ctx, params) {
      try {
        return await this.$axios.get(`${subscriptionsInvoiceAPI}/${params}`)
      } catch (error) {
        return error
      }
    },
    async fetchSubscriptionsPackages(ctx, queryParams) {
      try {
        return await this.$axios.get(subscriptionsPackagesAPI, { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async fetchSubscriptionsUserTypes(ctx, queryParams) {
      try {
        return await this.$axios.get(subscriptionsUserTypesAPI, { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async fetchSubscriptionsExport(ctx, queryParams) {
      try {
        return await this.$axios.get(subscriptionsExportAPI, { params: queryParams })
      } catch (error) {
        return error
      }
    },
  },
}
